import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {
    Select,
    MenuItem,
  } from '@material-ui/core';
  import Button from '@material-ui/core/Button';

import { useForm } from '../../utils/useForm';
import { captions } from '../../config/';


export const CreateUserDialog = ({onSubmit, isOpen, setIsOpen, classes}) => {
    const [userType ,setUserType] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState('');

    const [values, handleChange] = useForm({
        auth0Id: '',
        deviceId: '',
        email: '',
        platform: '',
        scooterId: '',
        status: '',
        type: '',
        subscriptionCustomerId: '',
      });
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create new user</DialogTitle>
            <DialogContent
                className={classes.dialogContainer}
            >

                <div className={classes.formWrap}>
                <TextField
                    className={classes.deviceInput}
                    autoFocus
                    required
                    name="auth0Id"
                    id="auth0Id"
                    label="auth0Id"
                    type="text"
                />

                <TextField
                    className={classes.deviceInput}
                    required
                    name="email"
                    id="email"
                    label="email"
                    type="text"
                />

                <TextField
                    className={classes.deviceInput}
                    required
                    name="scooterId"
                    id="scooterId"
                    label="scooterId"
                    type="text"
                    onChange={handleChange}
                />

                <Select
                    value={userType}
                    onChange={({target}) => setUserType(target.value)}
                    className={classes.deviceInput}
                >
                    <MenuItem value="ZEWAY">ZEWAY</MenuItem>
                    <MenuItem value="CUSTOMER">CUSTOMER</MenuItem>
                </Select>
                <Select
                    value={subscriptionStatus}
                    onChange={({target}) => setSubscriptionStatus(target.value)}
                    className={classes.deviceInput}
                >
                    <MenuItem value="NEW">NEW</MenuItem>
                    <MenuItem value="PENDING">PENDING</MenuItem>
                    <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                    <MenuItem value="VALID">VALID</MenuItem>
                    <MenuItem value="CANCELED">CANCELED</MenuItem>
                </Select>

                <TextField
                    className={classes.deviceInput}
                    style={{marginTop: 15}}
                    required
                    name="subscriptionCustomerId"
                    id="subscriptionCustomerId"
                    label="Customer ID in Chargebee"
                    type="text"
                />


                </div>

        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setIsOpen(false)}
            >
            {captions.cancel}
          </Button>
          <Button
            // disabled={!notificationMessage}
            onClick={() => {
                setIsOpen(false)}
            }
            color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
};
