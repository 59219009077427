import React from 'react';
import PropTypes from 'prop-types';

import { TextField, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
    width: '30ch',
    textTransform: 'capitalize',
  },
}));

const EnhancedInput = ({ name, value, onSubmit }) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        name={name}
        className={classes.input}
        value={value}
        id={name}
        label={name}
      />
      <Button onClick={() => onSubmit(name, value)} color="primary">
        Search
      </Button>
    </div>
  );
};

EnhancedInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EnhancedInput;
