import React, { memo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import {
  makeStyles,
  lighten,
  Toolbar,
  Typography,
  Tooltip
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// Constants
import { captions } from '../../config/';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light' ?
      {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      } :
      {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = ({ numSelected, onDeleteClick, onSendNotification }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div">
          {`${numSelected} ${captions.selected}`}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div">
          {captions.admin}
        </Typography>
      )}

      {/* {
        numSelected > 0 && (
          <Tooltip title={'Send notification to users'}>
            <IconButton onClick={onSendNotification} aria-label="send">
              <SendIcon/>
            </IconButton>
          </Tooltip>
        )
      }
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={onDeleteClick} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};


export default memo(EnhancedTableToolbar);
