import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  };

  render() {
    if (this.state.hasError) {
      return <p>Oops, something went wrong</p>
    }

    return this.props.children
  };
}

export default ErrorBoundary;
