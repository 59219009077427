import React, { useEffect, useState, memo, useCallback } from 'react';
// TODO: fold all import to one;
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

// utils
import { useForm } from '../../utils/useForm';
// constants
import { captions } from '../../config/';

const FormModal = ({ classes, onSubmit }) => {
  // TODO: useReducer
  const [open, setOpen] = useState(false);
  const [values, handleChange] = useForm({
    createAuth0Id: '',
    createScooterId: '',
    platform: '',
  });

  const { createScooterId, createAuth0Id, platform } = values;

  const [valuesExist, setValuesExist] = useState(false);

  useEffect(() => {
    setValuesExist(Boolean(createAuth0Id || createAuth0Id || platform));
  }, [createScooterId, createAuth0Id, platform]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCreateAssociationClick = useCallback(() => {
    onSubmit(createAuth0Id, createScooterId, platform);
    setOpen(false);
    setValuesExist(false);
  }, [createAuth0Id, createScooterId, platform, onSubmit]);
  // TODO: move all primitives to constants
  return (
    <div className={classes.createButton}>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {captions.createNewAssociation}
      </Button>
      <Dialog
        open={open}
        keepMounted={valuesExist}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{captions.createNewAssociation}</DialogTitle>
        <DialogContent
          className={classes.dialogContainer}
          onChange={handleChange}>
          <DialogContentText>
            {captions.provideAnId}
          </DialogContentText>
          <DialogContentText>
            <TextField
              className={classes.deviceInput}
              autoFocus
              required
              name="createAuth0Id"
              id="createAuth0Id"
              label="Auth0 Id"
              type="text"
            />
            <TextField
              required
              name="createScooterId"
              className={classes.modalInputs}
              id="createScooterId"
              label="Scooter Id"
              type="text"
            />
          </DialogContentText>
          {/* <TextField
            onChange={handleChange}
            className={classes.select}
            required
            id="platform"
            name="platform"
            label="Platform"
            value={platform}
            select
          >
            <MenuItem value="IOS">{captions.ios}</MenuItem>
            <MenuItem value="ANDROID">{captions.android}</MenuItem>
          </TextField> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {captions.cancel}
          </Button>
          <Button
            disabled={!createAuth0Id || !createScooterId}
            onClick={handleCreateAssociationClick}
            color="primary">
            {captions.create}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FormModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(FormModal);

