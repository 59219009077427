import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';

import {Auth0Provider} from './AuthProvider';
import history from './utils/history';
import config from './auth_config.json';

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};


ReactDOM.render(
    <React.StrictMode>
      {/* <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      > */}
        <App />
      {/* </Auth0Provider> */}
    </React.StrictMode>,
    document.getElementById('root')
);
