// TODO: move to constants
export const LOCAL_BASE_URL = 'https://app.zeway.com';
// GET
export const SCOOTER_DEVICE_LIST = '/scooterdevice';
export const GET_ITEM_BY_ID = '/scooterdevice/filter/';
// POST
export const ADD_SCOOTER = '/scooterdevice';
// DELETE
export const DELETE_SCOOTER = '/scooterdevice';
//CHANGE TYPE
export const CHANGE_TYPE = '/customers/type?type=#type#&id=#id#';
//CHANGE STATUS
export const CHANGE_STATUS = '/customers/status?status=#status#&id=#id#';

//LOGIN
export const LOGIN = '/auth/login'

//SEND NOTIFICATIONS
export const SEND_NOTIFICATIONS = '/customers/push/list';

export const CUSTOMER_ROUTE = '/customers'
export const EDIT_CHARGEBEE = '/customers/chargebee';
