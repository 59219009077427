import React, { memo } from 'react';
import { TableHead, TableCell, Checkbox, TableRow, } from '@material-ui/core';
import { headings } from '../../config/';
import PropTypes from 'prop-types';


const EnhancedTableHead = ({ onSelectAllClick, numSelected, rowCount, onDeleteClick, onSendNotification }) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headings.map((headCell, index) => (
          <TableCell key={`${headCell}-${index}`} align="center">
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default memo(EnhancedTableHead);
