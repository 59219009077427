import React, { useState, memo, useEffect, useCallback, useMemo } from 'react';
import {
  AppBar,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  makeStyles,
  Checkbox,
  CircularProgress,
  Box,
  Container,
  Select,
  MenuItem,
  Collapse,
  TableHead,
  IconButton,
  Button,
  TablePagination
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
// components
import EnhancedInput from '../inputs/EnhancedInput';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import FormModal from '../modals/FormModal';
import {SendNotificationsForm} from '../modals/SendNotificationsForm';
import { UpdateUserForm } from '../modals/UpdateUserForm';
import { CreateUserDialog } from '../modals/CreateUserDialog';
// constants
import {
  SCOOTER_DEVICE_LIST,
  DELETE_SCOOTER,
  ADD_SCOOTER,
  GET_ITEM_BY_ID,
  CHANGE_TYPE,
  CHANGE_STATUS,
  SEND_NOTIFICATIONS,
  CUSTOMER_ROUTE,
  EDIT_CHARGEBEE
} from '../../api/client';
import { captions, measurments } from '../../config';
// utils
import { setUniqueIDs } from '../../utils/helpers.js';
import { useForm } from '../../utils/useForm';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '400px',
    padding: '0!important'
  },
  tableCellWithoutPadding: {
    padding: '0!important',
  },
  buttonsCell: {
    display: 'flex',
    border: 'none',
    '& button': {
      maxWidth: '30%'
    },
    eight: "78px",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: '78px',
  },
  tableRow: {
    cursor: 'pointer',
    height: '78px',
  },
  container: {
    maxHeight: '700px',
    marginTop: '30px',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-20px',
    marginLeft: '-25px',
    textAlign: 'center',
    fontSize: '10px',
  },
  spinnerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'aliceblue',
    right: 0,
    bottom: 0,
    zIndex: 3,
    opacity: 0.7,
  },
  appHeader: {
    padding: '14px 0px 24px 0px',
    position: 'unset',
    flexDirection: 'row',
    boxShadow: 'unset',
    justifyContent: 'space-around',
    '& > form': {
      display: 'flex',
      flexDirection: 'column',

      '& > div': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  },
  buttonsWrap: {
    padding: '14px 0px 24px 0px',
    position: 'unset',
    flexDirection: 'row',
    boxShadow: 'unset',
    justifyContent: 'space-around',
    '& > form': {
      display: 'flex',
      flexDirection: 'column',

      '& > div': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  },
  form: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  createButton: {
    alignSelf: 'center',
  },
  modalInputs: {
    width: '17ch',
    marginLeft: theme.spacing(4),
  },
  emptyDataPlaceholder: {
    margin: theme.spacing(10),
  },
  select: {
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(3),
    width: '12ch',
  },
  dialogContainer: {
    maxWidth: '750px',
    display: 'flex',
    flexDirection: 'column',
  },
  deviceInput: {
    width: '31ch',
    height: '60px',
  },
  deviceId: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'pre',
  },
  formWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minWidth: 400,

    '& > div': {
      width: '100%',
    }
  },
  externalId: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    justifyContent: "center",
  },
  subscriptionsDiv: {
    display: "flex",
    justifyContent: "center",

    '& > ul': {
      padding: 0,
      width: "100%",
      justifyContent: "center",
    },
  }
}));

const AdminTable = ({filterByStatus}) => {
  // TODO: rewrite with useReducer
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState({});
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(null);

  const [values, handleChange] = useForm({ auth0: '', scooterId: '', email: '', deviceId: '', plateNumber: "" });

  const [isOpen, toggleIsOpen] = useState({});

  const [isSubmitDeleteDialogOpen, setIsSubmitDeleteDialogOpen] = useState(false);


  const getDevices = async (page=currentPage, size=rowsPerPage) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      //GET TOTAL ROWS IN DB (pagination)
      const { data } = await axios.get(`${SCOOTER_DEVICE_LIST}/size`, { headers: {
        Authorization: `Bearer ${token}`
      }});
      setTotalRows(data);
      const response = await axios.get(`${SCOOTER_DEVICE_LIST}?page=${page+1}&size=${size}`, { headers: {
        Authorization: `Bearer ${token}`
      }});
      setData(setUniqueIDs(response.data));
    } catch (error) {
      console.error(error);
      setIsAuthenticationError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //PAGINATION
  const handlePageChange = (e, page) => {
    setCurrentPage(page);
    getDevices(page);
  };
  const handleSizeChange = (e) => {
    setRowsPerPage(e.target.value);
    getDevices(currentPage, e.target.value);
  };


  useEffect(() => {
    setIsLoading(true);
    setCurrentPage(0);
    const token = localStorage.getItem('token');

    try {
      if (filterByStatus === 'ALL') {
        getDevices();
        return;
      }
      (async function(){
        const status = filterByStatus === 'ALL' ? '' : `?status=${filterByStatus}`
        await axios.get(`${SCOOTER_DEVICE_LIST}/filter/size${status}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }})
          .then(({data}) => {
            setTotalRows(data);
          })
          .then(() => {
            (async function() {
              return await axios.get(`${GET_ITEM_BY_ID}?page=${currentPage+1}&size=${rowsPerPage}&status=${filterByStatus}`, {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })
              .then(({data}) => {
                setSelected([]);
                setData(setUniqueIDs(data));
              })
            })();
          })
      })();

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  },[filterByStatus]);
  const handleDeleteSubscription = async (customerId,externalId) => {
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`${EDIT_CHARGEBEE}`, { headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        customerId,
        "subscriptions": [
             {
              externalId
             }
         ]
      }
    });
    } catch (error) {
      console.error(error);
      setIsAuthenticationError(true);
    } finally {
      for(const key in values) {
        if(values[key]) {
          return handleSearch(key,values[key]);
        }
      }
      getDevices();
    }
  }
  const [selected, setSelected] = useState([]);

  const handleClick = useCallback((_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }, [selected]);


  //UNUSED
  const handleTypeChange = useCallback(async(auth0id, type) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const route = CHANGE_TYPE.replace('#type#',type).replace('#id#',auth0id.split('|')[1]);
      await axios.put(route,{
        headers: {Authorization: `Bearer ${token}`}
      })
    }
    catch(err) {
      console.log('error while changing type',err);
    }
    finally {
      for(const key in values) {
        if(values[key]) {
          return handleSearch(key,values[key]);
        }
      }
      getDevices();
    }
  });

  //UNUSED
  const handleStatusChange = useCallback(async(auth0id, status) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const route = CHANGE_STATUS.replace('#status#',status).replace('#id#',auth0id.split('|')[1]);
      await axios.put(route,{
        headers: {Authorization: `Bearer ${token}`}
      })
    }
    catch(err) {
      console.log('error while changing status');
    }
    finally {
      for(const key in values) {
        if(values[key]) {
          return handleSearch(key,values[key]);
        }
      }
      getDevices();
    }
  });

  const handleSelectAllClick = useCallback((event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  }, [data]);

  const handleSendNotificationClick = async (notificationMessage, notificationTitle) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    const preparedData = data.filter((item) => selected.includes(item.id));
      const mappedData = preparedData.map(({customerId}) => customerId);
    try {
      await axios.put(SEND_NOTIFICATIONS, {
          customerIds:[ ...mappedData ],
          "title": notificationTitle,
          "message": notificationMessage
        },
        {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setSelected([]);
    }
    catch (err) {
      console.log('error',err)
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = useCallback(async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      const preparedData = data.filter((item) => selected.includes(item.id));
      const mappedData = preparedData.map(({scooterId,auth0Id}) => ({scooterId, customerId: auth0Id}));

      await axios.delete(DELETE_SCOOTER, {
        data: {
          scooterDeviceIdsList: mappedData,
        },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`
        },
      });

      setSelected([]);
      setData(data.filter((item) => !selected.includes(item.id)));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [data, selected]);

  const handleCreateAssociation = async (auth0Id, scooterId) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      await axios.post(ADD_SCOOTER, null, {
        params: {
          scooterId,
          customerId: auth0Id,
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      getDevices();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateUser = () => {
    try {
      setIsLoading(true);
      alert('send request')
    }
    catch(error) {
      console.log(error)
    }
    finally {
      for(const key in values) {
        if(values[key]) {
          return handleSearch(key,values[key]);
        }
      }
      getDevices();
    }
  };
  const handleEditUser = (user) => {
    const token = localStorage.getItem('token');

    try {

      setIsLoading(true);
      const preparedSubscription = user.subscriptions.map(item => ({externalId: item, createdAt: null, agreement: null}));

      axios.put(EDIT_CHARGEBEE, {
        ...user,
        subscriptions: preparedSubscription
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    }
    catch(error) {
      console.log(error)
    }
    finally {
      for(const key in values) {
        if(values[key]) {
          return handleSearch(key,values[key]);
        }
      }
      getDevices();
    }
  };
  const handleSearch = useCallback(async (name, value) => {
    setIsLoading(true);
    setCurrentPage(0);
    const token = localStorage.getItem('token');

    try {
      if (!value) {
        getDevices();
        return;
      }
      //GET TOTAL ROWS IN DB (pagination)
      const { data } = await axios.get(`${SCOOTER_DEVICE_LIST}/filter/size`, {
        params: {
          [name]: value,
        },
        headers: {
          Authorization: `Bearer ${token}`
        }});
      setTotalRows(data);

      const { data: foundData } = await axios.get(`${GET_ITEM_BY_ID}?page=${currentPage+1}&size=${rowsPerPage}`, {
        params: {
          [name]: value,
        },
        headers: {Authorization: `Bearer ${token}`}
      });

      setSelected([]);
      setData(setUniqueIDs(foundData));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteUser = async ({customerId}) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');

    try {
      await axios.delete(`${CUSTOMER_ROUTE}?id=${customerId}`,{
        headers: {Authorization: `Bearer ${token}`}
      })
    }
    catch (err) {
      console.log(err);
    }
    finally {
      await getDevices();
    }
  };
  const isSelected = useCallback((name) => {
    return selected.indexOf(name) !== -1;
  }, [selected]);

  const classes = useStyles();

  const toggleDropdown = (event,rowId) => {
    event.preventDefault();

    toggleIsOpen(prevState => ({...prevState, [rowId]: !!!prevState[rowId]}))
  };

  const preparedData = useMemo(() => {
    const filteredData = uniqBy(data,'auth0Id');
    const grouped = groupBy(data,'auth0Id');

    const resultArr = filteredData.map(item => {
      let devices = grouped[item.auth0Id].map(item => item.deviceId).filter(item => item);

      return {
        ...item,
        devices
      }
    });

    return (resultArr.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      return (
        <>
          <TableRow
            className={[classes.tableRow, classes.tableCellWithoutPadding]}
            selected={isItemSelected}
            key={`${row.id}${index}`}>
            <Checkbox checked={isItemSelected} onClick={(event) => handleClick(event, row.id)} />
            {row.devices.length ? (
              <TableCell>
                <IconButton
                 onClick={event => toggleDropdown(event,row.id)}
                >
                  {!!isOpen[row.id] ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </TableCell>
            ) : <TableCell/>}
            <TableCell
              className={classes.tableCell}
              component="td"
              align="center"
              scope="row">
              {row.auth0Id}
            </TableCell>
            <TableCell align="center" className={classes.tableCellWithoutPadding}>{row.email}</TableCell>
            <TableCell align="center" className={classes.tableCellWithoutPadding}>{row.plateNumber}</TableCell>
            <TableCell align="center" className={classes.tableCellWithoutPadding}>{row.scooterId}</TableCell>
            {/* <TableCell align="center">{row.subscriptionCustomerId}</TableCell> */}
            <TableCell className={classes.tableCellWithoutPadding}>
              <div className={classes.subscriptionsDiv}>
                <ul>
                  {
                    row.subscriptions.map(({ externalId }) => (
                      <li key={externalId} className={classes.externalId}>
                        {externalId}
                        <IconButton onClick={() => handleDeleteSubscription(row.customerId,externalId)}><DeleteOutlineIcon/></IconButton>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </TableCell>
            {/* <TableCell className={classes.tableCellWithoutPadding}>
              <Select
                value={row.type}
                onChange={({target}) => handleTypeChange(row.auth0Id, target.value)}
              >
                <MenuItem value="ZEWAY">ZEWAY</MenuItem>
                <MenuItem value="CUSTOMER">CUSTOMER</MenuItem>
              </Select>
            </TableCell>
            <TableCell className={classes.tableCellWithoutPadding}>
              <Select
                value={row.status}
                onChange={({target}) => handleStatusChange(row.auth0Id, target.value)}
              >
                <MenuItem value="NEW">NEW</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
                <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                <MenuItem value="VALID">VALID</MenuItem>
                <MenuItem value="CANCELED">CANCELED</MenuItem>
              </Select>
            </TableCell> */}
            <TableCell className={`${classes.tableCellWithoutPadding} ${classes.buttonsCell}`}>
            <Button variant="contained" color="primary" onClick={() => setUserToUpdate(row)} style={{width: '100%', marginBottom: 5 }}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={() => deleteUser(row)} style={{width: '100%', marginBottom: 5 }}>
              Delete
            </Button>
            </TableCell>
          </TableRow>
          {
            row.devices.length ? (
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={!!isOpen[row.id]} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Device ID</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.devices.map((deviceId) => (
                            <TableRow key={deviceId}>
                              <TableCell component="th" scope="row"  className={classes.deviceId}>
                                {deviceId}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            ) : null
          }
        </>
      );
    }));
  }, [data, classes, selected, handleClick, isSelected, isOpen]);

  if(isAuthenticationError) return <h1>You don't have access rights</h1>;

  return (
    <>
      <Container maxWidth="xl">
        <AppBar className={classes.appHeader} color="transparent">
          <form
            onChange={handleChange}
            className={classes.form}
            noValidate
            autoComplete="off"
          >
            <EnhancedInput
              onSubmit={handleSearch}
              name={captions.auth0Id}
              value={values.auth0}
            />
            <EnhancedInput
              onSubmit={handleSearch}
              name={captions.email}
              value={values.email}
            />
            <EnhancedInput
              onSubmit={handleSearch}
              name={captions.scooterId}
              value={values.scooterId}
            />
            <EnhancedInput
              onSubmit={handleSearch}
              name={captions.deviceId}
              value={values.deviceId}
            />
            <EnhancedInput
              onSubmit={handleSearch}
              name={captions.plateNumber}
              value={values.plateNumber}
            />
          </form>
          <SendNotificationsForm classes={classes} selected={selected} isOpen={isSendNotificationModalOpen} handleClose={setIsSendNotificationModalOpen} handleSendNotification={handleSendNotificationClick}/>
          <FormModal classes={classes} onSubmit={handleCreateAssociation} />
          <UpdateUserForm user={userToUpdate} onSubmit={handleEditUser} classes={classes}/>
          <CreateUserDialog onSubmit={handleCreateUser} classes={classes} isOpen={isCreateUserModalOpen} setIsOpen={setIsCreateUserModalOpen}/>
        </AppBar>

        <AppBar className={classes.buttonsWrap} color="transparent">
          <Button>SEARCH FEATURE</Button>

          <Button onClick={() => setIsCreateUserModalOpen(true)}>Create New User</Button>
          <Button disabled={!!!selected.length} onClick={() => setIsSendNotificationModalOpen(true)}>SEND NOTIFICATION</Button>
          <Button disabled={!!!selected.length} onClick={handleDeleteClick}>DELETE</Button>
        </AppBar>
        {data.length ? (
          <>
          <TableContainer className={classes.container} component={Paper}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onDeleteClick={handleDeleteClick}
              onSendNotification={() => setIsSendNotificationModalOpen(true)}
            />

            <Table stickyHeader className={classes.table}>
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={data.length}
              />
              <TableBody>
                {preparedData}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            color="primary"
            page={currentPage}
            onChangePage={handlePageChange}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleSizeChange}
          />

          </>
        ) : (
          <Box component="div" m="1">
            <Typography
              className={classes.emptyDataPlaceholder}
              color="secondary"
              variant="h4"
              component="h4"
              align="center">
              {captions.nothingToShow}
            </Typography>
          </Box>
        )}
      </Container>
      <Dialog
            open={isSubmitDeleteDialogOpen}
            onClose={() => setIsSubmitDeleteDialogOpen(false)}
            aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">ARE YOU SURE TO DELETE THAT USER?</DialogTitle>

        {
            <DialogActions>
              <Button
                color="primary"
                >
                {captions.cancel}
              </Button>
              <Button
                color="primary">
                Send
              </Button>
            </DialogActions>
        }
      </Dialog>
      {isLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} size={measurments.circularProgressSize} />
        </div>
      )}
    </>
  );
};

export default memo(AdminTable);
