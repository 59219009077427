import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Select,
    MenuItem,
  } from '@material-ui/core';

import { captions } from '../../config/';
import { useForm } from '../../utils/useForm';
import { stat } from 'fs';

export const UpdateUserForm = ({classes, user, onSubmit}) => {
    const { auth0Id, deviceId, email, platform, customerId, scooterId, status, type, subscriptionCustomerId, subscriptions } = user;

    const [isOpen,setIsOpen] = useState(false);
    const [subscriptionsList, setSubscriptionList] = useState([]);
    const [newSubscription, setNewSubscription] = useState('');
    const [newSubscriptionsList, setNewSubscriptionsList] = useState([]);

    const [userType, setUserType] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState('');

    useEffect(() => {
        setUserType(type);
        setSubscriptionStatus(status);
    }, [type,status]);
    useEffect(()=> {
        if(user.auth0Id) {
            setIsOpen(true);
            setSubscriptionList([...user.subscriptions.map(({ externalId}) => externalId)])
        }
    },[user]);

    const handleDelete = id => {
        setSubscriptionList(prevState => ([...prevState.filter(externalId => externalId !== id)]))
    }
    const [values, handleChange] = useForm({
        auth0Id: '',
        deviceId: '',
        email: '',
        platform: '',
        scooterId: '',
        status: '',
        type: '',
        subscriptionCustomerId: '',
      });
      const handleAdd = () => {
          setNewSubscriptionsList(prevState => ([...prevState, newSubscription]))
        //   setSubscriptionList(prevState => ([...prevState, newSubscription]));
          setNewSubscription('');
      }
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit user</DialogTitle>
            <DialogContent
                className={classes.dialogContainer}
                onChange={handleChange}
            >
            
                <div className={classes.formWrap}>
                <TextField
                    className={classes.deviceInput}
                    autoFocus
                    required
                    name="auth0Id"
                    id="auth0Id"
                    label="auth0Id"
                    type="text"
                    defaultValue={auth0Id}
                    inputProps={{
                        disabled: true
                    }}
                />

                <TextField
                    className={classes.deviceInput}
                    autoFocus
                    required
                    name="email"
                    id="email"
                    label="email"
                    type="text"
                    defaultValue={email}
                    inputProps={{
                        disabled: true
                    }}
                />

                <TextField
                    className={classes.deviceInput}
                    required
                    name="scooterId"
                    id="scooterId"
                    label="scooterId"
                    type="text"
                    defaultValue={scooterId}
                    onChange={handleChange}
                    value={scooterId}
                />

                <Select
                    value={userType} 
                    onChange={({target}) => setUserType(target.value)}
                    defaultValue={type}
                >
                    <MenuItem value="ZEWAY">ZEWAY</MenuItem>
                    <MenuItem value="CUSTOMER">CUSTOMER</MenuItem>
                </Select>
                <Select
                    value={subscriptionStatus} 
                    onChange={({target}) => setSubscriptionStatus(target.value)} 
                    defaultValue={status}
                >
                    <MenuItem value="NEW">NEW</MenuItem>
                    <MenuItem value="PENDING">PENDING</MenuItem>
                    <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                    <MenuItem value="VALID">VALID</MenuItem>
                    <MenuItem value="CANCELED">CANCELED</MenuItem>
                </Select>
            
                <TextField
                    className={classes.deviceInput}
                    required
                    name="subscriptionCustomerId"
                    id="subscriptionCustomerId"
                    label="Customer ID in Chargebee"
                    type="text"
                    defaultValue={subscriptionCustomerId}
                />
                <ul>
                    {
                         subscriptionsList.map((item, index) => (
                            <li key={index}><span>{item}</span>
                                {/* <Button variant="outlined" color="secondary" onClick={() => handleDelete(item)}>Delete</Button> */}
                            </li>
                        ))
                    }
                    {
                        newSubscriptionsList.map((item, index) => (
                            <li key={index}><span>{item}</span></li>
                        ))
                    }
                    <TextField
                        classes={classes.deviceInput}
                        name={'newSubscription'}
                        id={'newSubscription'}
                        label={'Subscription ID'}
                        type={'text'}
                        value={newSubscription}
                        onChange={e => setNewSubscription(e.target.value)}
                    />

                    <Button variant='contained' color='primary' onClick={handleAdd}>Add Subscription</Button>
                </ul>
                </div>

        </DialogContent>
        <DialogActions>
          <Button 
            color="primary"
            onClick={() => setIsOpen(false)}
            >
            {captions.cancel}
          </Button>
          <Button
            // disabled={!notificationMessage}
            onClick={() => {
                onSubmit({subscriptions: newSubscriptionsList, subscriptionCustomerId: values.subscriptionCustomerId, customerId, status: subscriptionStatus, type: userType, scooterId: values.scooterId }); 
                setIsOpen(false)}
            }
            color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    )
}