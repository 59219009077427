import React, { useEffect, useState } from 'react';
import AdminTable from '../Table/Table';
import ErrorBoundary from '../../utils/ErrorBoundary';
import classnames from 'classnames';
import { measurments } from '../../config';
// import {useHistory} from 'react-router-dom';
import {
  makeStyles,
  Button,
  InputLabel,
  FormHelperText,
  
  Input
} from '@material-ui/core';

import {LOGIN} from '../../api/client';

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-20px',
    marginLeft: '-25px',
    textAlign: 'center',
    fontSize: '10px',
  },
  spinnerContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'aliceblue',
    right: 0,
    bottom: 0,
    zIndex: 3,
    opacity: 0.7,
  },
  login: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 60,
    color: 'red',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #000',
    padding: '20px 0',

    '& div': {
      display: 'flex',

      '& button.active': {
        color: '#D3455B',
      }
    }
  },
  form: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: '20px 0 10px',
      minWidth: 320
    },
  },
  formWrap: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  }
}));


const App = (props) => {
  const classes = useStyles();

  // const {push} = useHistory();
  const [errorText, setErrorText] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [filterByStatus, setFilterByStatus] = useState('ALL');

  // const { loginWithPopup, isAuthenticated, loading, logout } = useAuth0();
  
  const handleLogin = (e) => {
    e.preventDefault();
    setErrorText('');
    const [email, password] = e.target.elements

    fetch(LOGIN, {
      method: 'POST',
      body: JSON.stringify({ email: email.value, password: password.value}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      if(data.token.length < 20) {
        return setErrorText(data.token)
      }
      setIsAuthenticated(true);
      localStorage.setItem('token', data.token)
    })
    .catch(err => console.log(err));
  }

  const logoutHandler = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  return (
    <div className="App">
    { isAuthenticated && 
      <header className={classes.header}>
        <div>
          <Button className={classnames({active: filterByStatus === 'ALL'})} onClick={() => setFilterByStatus('ALL')}>View All</Button>
          <Button className={classnames({active: filterByStatus === 'VALID'})} onClick={() => setFilterByStatus('VALID')}>Valid</Button>
          <Button className={classnames({active: filterByStatus === 'PENDING'})} onClick={() => setFilterByStatus('PENDING')}>Pending</Button>
          <Button className={classnames({active: filterByStatus === 'NEW'})} onClick={() => setFilterByStatus('NEW')}>New</Button>
        </div>
        <Button onClick={logoutHandler} variant="outlined" color={'secondary'}>Logout</Button>
      </header>
    }
    
      
      <ErrorBoundary>
        {/* {loading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinner} size={measurments.circularProgressSize} />
        </div>
        
      )} */}
        {/* {!isAuthenticated && !loading && <h1 className={classes.login} onClick={loginWithPopup}>Please, login</h1>} */}
        
        { isAuthenticated && <AdminTable filterByStatus={filterByStatus}/> }

        {!isAuthenticated && <div className={classes.formWrap}>
          <form onSubmit={handleLogin} className={classes.form}>
              <InputLabel htmlFor="email">Email address</InputLabel>
              <Input id="email" aria-describedby="email" error={!!errorText} helpertext={errorText}/>

              <InputLabel htmlFor="password">Password</InputLabel>
              <Input id="password" aria-describedby="password" type={'password'} error={!!errorText} helpertext={errorText}/>

              {errorText && <FormHelperText>{errorText}</FormHelperText>}
              <Button type="submit" variant={'contained'} color="primary">Login</Button>
            </form>
        </div>}
        
      </ErrorBoundary>
    </div>
  );
};

export default App;
