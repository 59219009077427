export default {
  nothingToShow: 'Nothing to show',
  deviceId: 'deviceId',
  auth0Id: 'auth0',
  plateNumber: 'plateNumber',
  email: 'email',
  scooterId: 'scooterId',
  createDeviceId: 'createDevcieId',
  createScooterId: 'createScooterId',
  selected: 'selected',
  admin: 'Admin',
  createNewAssociation: 'Create new association',
  provideAnId: 'To create new association you must provide DeviceId and ScooterId',
  ios: 'IOS',
  android: 'ANDROID',
  cancel: 'Cancel',
  create: 'Create',
};
