import React, {useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { captions } from '../../config/';

export const SendNotificationsForm = ({classes, selected, isOpen, handleClose,handleSendNotification}) => {
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationTitle, setNotificationTitle] = useState('');
    const [isNotificationSend, setIsNotificationSend] = useState(false);
    const handleSend = () => {
        handleSendNotification(notificationMessage, notificationTitle);
        setIsNotificationSend(true);

        setNotificationMessage('');
        setNotificationTitle('');
        setTimeout(() => {
          handleClose(false);
          setIsNotificationSend(false);
        }, 2000)
    };
    return (
        <Dialog
            open={isOpen}
            onClose={() => handleClose(false)}
            aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Send notification to users</DialogTitle>
        <DialogContent
          className={classes.dialogContainer}
        >
          {
            isNotificationSend ? (
              <h1>Notification send</h1>
            ) : (
              <>
                <DialogContentText>
                  Notification will be sent to {selected.length} users
                </DialogContentText>
                <TextField
                  className={classes.deviceInput}
                  autoFocus
                  required
                  name="notificationTitle"
                  id="notificationTitle"
                  label="Notification Title"
                  type="text"
                  value={notificationTitle}
                  onChange={e => setNotificationTitle(e.target.value)}
                />
                <TextField
                  className={classes.deviceInput}
                  required
                  name="notificationMessage"
                  id="notificationMessage"
                  label="Notification Message"
                  type="text"
                  value={notificationMessage}
                  onChange={e => setNotificationMessage(e.target.value)}
                />
              </>
            )
          }


        </DialogContent>
        {
          !isNotificationSend && (
            <DialogActions>
              <Button
                color="primary"
                onClick={() => handleClose(false)}
                >
                {captions.cancel}
              </Button>
              <Button
                disabled={!notificationMessage}
                onClick={handleSend}
                color="primary">
                Send
              </Button>
            </DialogActions>
          )
        }
      </Dialog>
    )
}
